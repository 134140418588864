<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        class="title"
      >
        Email
      </v-col>
    </v-row>
    <v-row
      v-if="!showEditEmail"
      no-gutters
    >
      <v-col cols="12">
        <v-btn
          v-show="!showEditEmail"
          color="secondary"
          outlined
          @click.prevent="showEditEmail = true"
        >
          Change Email
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      v-else
      class="mb-9 mt-2"
      no-gutters
    >
      <v-col cols="12">
        <ValidationObserver ref="observer">
          <v-form
            @submit.prevent="onSubmitChangeEmail"
          >
            <v-row>
              <v-col cols="12">
                <v-text-field
                  :value="getProfile.email"
                  placeholder="Current Email"
                  label="Current Email"
                  disabled
                  hide-details
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                class="py-1"
              >
                <ValidationProvider
                  ref="emailValidationProvider"
                  v-slot="{ errors }"
                  rules="required"
                  name="new email"
                >
                  <v-text-field
                    v-model="newEmail"
                    placeholder="New Email"
                    autocomplete="off"
                    label="New Email*"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
              <v-col
                cols="12"
                class="py-1"
              >
                <ValidationProvider
                  ref="emailConfirmValidationProvider"
                  v-slot="{ errors }"
                  rules="required|confirmed:new email"
                  name="confirmed email"
                >
                  <v-text-field
                    v-model="confirmEmail"
                    placeholder="Confirm Email"
                    autocomplete="off"
                    label="Confirm Email*"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col cols="12">
                <v-text-field
                  hidden
                  label="Email"
                  placeholder="Email"
                  style="height: 1px; opacity: 0;"
                  tabindex="-1"
                  autocomplete="username"
                />
                <ValidationProvider
                  ref="currentPasswordValidation"
                  v-slot="{ errors }"
                  rules="required"
                  name="password"
                  vid="Password"
                >
                  <v-text-field
                    v-model="currentPassword"
                    :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                    :type="showPassword ? 'text' : 'password'"
                    autocomplete="current-password"
                    placeholder="Password"
                    label="Password*"
                    :error-messages="errors"
                    @click:append="showPassword = !showPassword"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row class="mt-2">
              <v-col cols="12">
                <v-btn
                  color="primary"
                  type="submit"
                  :loading="isChangingEmail"
                >
                  Change Email
                </v-btn>
                <v-btn
                  outlined
                  color="primary"
                  class="ml-1"
                  :disabled="isChangingEmail"
                  @click="onClickCancel"
                >
                  Cancel
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </ValidationObserver>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ChangeEmailForm',
  data() {
    return {
      showEditEmail: false,
      showPassword: false,
      newEmail: '',
      confirmEmail: '',
      currentPassword: '',
      isChangingEmail: false,
    };
  },
  computed: {
    ...mapGetters(['getProfile']),
  },
  methods: {
    resetData() {
      this.showEditEmail = false;

      this.$nextTick(() => {
        this.newEmail = '';
        this.confirmEmail = '';
        this.currentPassword = '';

        if (this.$refs.observer) {
          this.$refs.observer.reset();
        }
      });
    },
    async onSubmitChangeEmail() {
      this.isChangingEmail = true;

      const isValid = await this.$refs.observer.validate();

      if (isValid) {
        try {
          await this.$store.dispatch('USER_UPDATE_EMAIL', {
            email: this.newEmail,
            password: this.currentPassword,
          });

          const { firstName, lastName, email } = this.getProfile;

          this.$zendesk.identify({
            name: `${firstName} ${lastName}`,
            email,
          });
          this.$zendesk.prefill({
            name: {
              value: `${firstName} ${lastName}`,
            },
            email: {
              value: email,
            },
          });

          this.resetData();
        } catch (error) {
          if (error.response) {
            switch (error.response.data) {
              case 'InvalidPassword':
                this.$refs.currentPasswordValidation.setErrors(['password is incorrect']);
                break;
              case 'DuplicateEmail':
                this.$refs.emailValidationProvider.setErrors(['Oops. Looks like you already have an account with this email address. Please try to login.']);
                break;
              default:
                // No default handling
            }
          }
        }
      }

      this.isChangingEmail = false;
    },
    onClickCancel() {
      this.resetData();
    },
  },
};
</script>
