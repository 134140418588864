<template>
  <v-row no-gutter>
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <ValidationProvider
        ref="datasetValidation"
        v-slot="{ errors }"
        rules="required"
        name="Dataset Id"
      >
        <v-text-field
          v-model.trim="datasetIdLocal"
          label="Dataset Id"
          :error-messages="errors"
        />
      </ValidationProvider>
      <div class="caption">
        Create a new Dataset in Facebook Business Manager. Click
        <a
          href="https://business.facebook.com/settings/events-dataset-news"
          target="_blank"
        >
          here</a>
        to go to the management page. Select the ad account you want to associate this data with.
        Auto tracking should be turned on by default so all future ads for the selected ad account
        will be associated with this dataset.
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'FacebookOfflineDestination',
  props: {
    errorMessage: {
      type: String,
      required: false,
      default: '',
    },
    datasetId: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      datasetIdLocal: this.datasetId,
    };
  },
  watch: {
    errorMessage() {
      if (this.errorMessage === 'InvalidDatasetId') {
        this.$refs.datasetValidation.setErrors(['Invalid / No Permissions for Dataset']);
      }
    },
  },
  methods: {
    getDetail() {
      return {
        datasetId: this.datasetIdLocal,
      };
    },
  },
};
</script>
